import React from "react";
import "./Validation.css";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
// import Photo from "./Photo";
import Zi from "./Zi";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Validation = (props) => {
  return (
    <>
      <div id="validation-menu" style={{display: props.toggleValidation ? 'block' : 'none'}}>
        {/*<Photo/>*/}
        <Zi/>
      </div>
      <button className={"btn-toggle-validation"} onClick={props.onValidationToggle}>
        <FontAwesomeIcon icon={faLayerGroup} size="2x" color={props.toggleValidation ? "var(--color-cyan)" : "var(--color-bg-stats)"}/>
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        toggleValidation: state.validation
    };
};

const mapDispatchToProps = dispatch => {
  return {
    onValidationToggle: () => dispatch(actionCreators.toggleValidation())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Validation);