// import WL from "../assets/WL1430.png";
// import deuxTubes from "../assets/Deux_tubes.png";
// import troisTubes from "../assets/Trois_tubes.png";
// import pdf from "../assets/pdf/AirSolid_Installation_Documentation.pdf";
import "./popup.css";

export const dmgBuildingStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "dmg-building-popup";
  let dmg = feature.graphic.attributes.dmg;
  div.innerHTML = "<p>Property damages: <b>" + dmg + "</b> €</p>";
  return (
    div
  )
};

export const sensorStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let PopupInfo = feature.graphic.attributes.PopupInfo;
  div.innerHTML = "<p>" + PopupInfo + "</p>"
  return (
    div
  )
};

export const vulnBuildingStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "vuln-building-popup";
  let TypeInfo = feature.graphic.attributes.Type;
  div.innerHTML = "<p>" + TypeInfo + "</p>"
  return (
    div
  )
};

export const buildingFreestyleStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let nbPerson = feature.graphic.attributes.Nb_person;
  let courriel = feature.graphic.attributes.courriel;
  let telephone = feature.graphic.attributes.telephone;
  div.innerHTML = "<li>Number of people : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
    "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
    "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone;
  return (
    div
  )
};

export const streetStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "street-popup";
  let HzVehDiss = feature.graphic.attributes.HzVehDiss;
  let color;
  if (HzVehDiss === "Low") {
    color = "green"
  } else if (HzVehDiss === "Medium"){
    color = "yellow"
  } else {
    color = "red"
  }
  div.innerHTML = "<p>Vehicular hazard: <span style='color:" + color + "'><b>" + HzVehDiss + "</b></span></p>";
  return (
    div
  )
};

// Pour Sacs de sable
export const bagStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "bag-popup";
  let HzPedDiss = feature.graphic.attributes.HzPedDiss;
  let color;
  if (HzPedDiss === "Low") {
    color = "green"
  } else if (HzPedDiss === "Medium"){
    color = "yellow"
  } else {
    color = "red"
  }
  div.innerHTML = "<p>Pedestrian hazard: <span style='color:" + color + "'><b>" + HzPedDiss + "</b></span></p>";
  return (
    div
  );
};

export const zi2Status = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "zi2-popup";
  let CODE = feature.graphic.attributes.CODE;
  // let Shape_Area = feature.graphic.attributes.Shape_Area;
  div.innerHTML = "<p>Code: <b>" + CODE + "</b></p>";
  return (
    div
  );
};

export const zi20Status = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "zi2-popup";
  let CODE = feature.graphic.attributes.CodPostal;
  div.innerHTML = "<p>Postal code: <b>" + CODE + "</b></p>";
  return (
    div
  );
};

// // Pour Sacs de sable DIGUE EXPRESS
// export const bagStatus = (feature) => {
//   let div = document.createElement("div");
//   div.className = "enun-popup";
//   div.id = "bag-popup";
//   let nbSacs = feature.graphic.attributes.Nb_Sacs;
//   let tooltipTxt = "";
//   let infoSacs, hauteurMax, modele, image, temps, nbOper, budget;
//
//   if (nbSacs === undefined) {
//     infoSacs = "<p>Information non disponible</p>";
//   } else {
//     let volume = feature.graphic.attributes.Volume.toFixed(2);
//     infoSacs = "<li>Nombre de sacs de sable: <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
//       "<li>Volume de sable: <span style='color:red'><b>" + volume + "</b></span> m³</li><br>";
//   }
//   let longueur = feature.graphic.attributes.longueur.toFixed(0);
//   let hauteur = feature.graphic.attributes.hauteur.toFixed(2);
//
//   if (hauteur <= 0.8) {
//     hauteurMax = 0.8;
//     modele = "2 bi-tubes Ø1000";
//     image = deuxTubes;
//     temps = 3.5;
//     nbOper = 3;
//     budget = 5000;
//   } else if (hauteur <= 1.25) {
//     hauteurMax = 1.25;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø600";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 7000;
//   } else if (hauteur <= 1.4) {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   } else {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   }
//   tooltipTxt =
//     infoSacs +
//     "<p class='enun-popup-title'>Digue Express®</p>" +
//     "<div class='model-container'><div class='model-text'>" + modele + "</div><img id='model-image' src=" + image + "></div>" +
//     "<li>Hauteur d'eau maximale prévue: <span style='color:red'><b>" + hauteur + "</b></span> m</li>" +
//     "<li>Hauteur de retenue maximale: <span style='color:red'><b>" + hauteurMax + "</b></span> m</li>" +
//     "<li>Longueur de la digue: <span style='color:red'><b>" + longueur + "</b></span> m</li>" +
//     "<li>Temps de mise en place: <span style='color:red'><b>" + temps + "</b></span> heures</li>" +
//     "<li>Nombre d'opérateurs: <span style='color:red'><b>" + nbOper + "</b></span></li>" +
//     "<li>Budget: <span style='color:red'><b>" + budget + "</b></span> $</li>" +
//     "<a href=" + pdf + " download='AirSolid_Installation_Documentation'>Télécharger les instructions et la documentation</a>";
//   div.innerHTML = tooltipTxt;
//   return (
//     div
//   );
// };