// Suez
export const SUEZ = {
  configName: 'suez',
  scenarioDepart: 11,
  nbScenario: 28,
  startScenario: 1,
  stepScenario: 1,
  labels: ["2", "6", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "110", "120", "130", "140", "150", "160", "170", "180", "190", "200", "250", "300", "350", "400", "450", "500"],
  dataKm: [],
  dataSacs: [],
  dataIsole: [],
  dataTot: [],
  dataEvac: [],
  waterPresentUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Badalona_prof_present_",
  waterFuturUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Badalona_prof_futur_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/DamageProperties/FeatureServer/",
  buildingPtsUrl: "",
  buildingFreestyleUrl: "",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/HzdVeh/FeatureServer/",
  // bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/HzdPedModified/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/HzdPed/FeatureServer/",
  incertMin_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Sensors/FeatureServer/",
  incertMax_Url: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Vulnerable_Infraestructures/FeatureServer/",
  photoUrls: [""],
  zi2Url:"https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/CensusDistricts/FeatureServer/",
  zi20Url:"https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/ZipCodes/FeatureServer/",
  centerView: [2.2375543, 41.4653203],
  centerViewZoom: 15,
  btn2019: 12,
  btnHier: 2,
  btnTempsReel: 6,
  btnUnJour: 8,
  btnTroisJours: 10,
  yearMarkers: [
    {
      value: 0,
      label: '2',
    },
    {
      value: 2,
      label: '10',
    },
    {
      value: 11,
      label: '100',
    },
    {
      value: 25,
      label: '400',
    }
  ],
  // isVisible: false
};

// Assomption
export const AS = {
  configName: 'assomption',
  scenarioDepart: 16,
  nbScenario: 81,
  startScenario: 1,
  stepScenario: 1,
  labels: ["60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240", "245", "250", "255", "260", "265", "270", "275", "280", "285", "290", "295", "300", "305", "310", "315", "320", "325", "330", "335", "340", "345", "350", "355", "360", "365", "370", "375", "380", "385", "390", "395", "400", "405", "410", "415", "420", "425", "430", "435", "440", "445", "450", "455", "460"],
  dataKm: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.2, 0.2, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.4, 0.4, 0.4, 0.4, 0.4, 0.4, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.6, 0.7, 0.8, 1.0, 1.1, 1.3, 1.5, 1.7, 1.8, 2.0, 2.1, 2.3, 2.8, 3.2, 3.5, 3.6, 4.0, 4.3, 4.5, 4.8, 5.1, 6.1, 6.6, 7.0, 7.3, 7.7, 8.1, 8.4, 8.6, 8.9, 9.2, 9.4, 9.6, 10.0, 10.3, 10.5, 10.7, 10.9, 11.2, 11.4],
  dataSacs: [0, 0, 0, 0, 0, 0, 27, 32, 39, 45, 52, 58, 62, 71, 80, 86, 93, 103, 113, 176, 208, 228, 282, 333, 454, 525, 598, 706, 816, 918, 1076, 1233, 1431, 1695, 1927, 2176, 2393, 2683, 3161, 3560, 4097, 4761, 5384, 6126, 6921, 7819, 8806, 9869, 11266, 12777, 14837, 16843, 19521, 22789, 26071, 29545, 33508, 37724, 42456, 47363, 52936, 59105, 67800, 76637, 86556, 96237, 107533, 120453, 133907, 148676, 164813, 182908, 200415, 218274, 237020, 257599, 278939, 299042, 321092, 342735, 365628],
  dataIsole: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 6, 6, 6, 6, 6, 6, 7, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 13, 16, 15, 16, 16, 30, 28, 28, 34, 39, 34, 34, 79, 88, 105, 103, 105, 106, 101, 99, 103, 112, 107, 106, 103, 85, 87, 82, 84, 72, 73, 70, 63, 61, 55, 49, 44],
  dataTot: [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 3, 3, 4, 4, 4, 4, 5, 7, 11, 12, 13, 13, 17, 20, 22, 24, 28, 30, 33, 46, 55, 66, 74, 87, 98, 109, 117, 127, 137, 142, 152, 163, 229, 254, 273, 291, 312, 334, 351, 378, 394, 411, 422, 438, 451, 464, 476, 488, 503, 509, 516],
  dataEvac: [13, 13, 13, 13, 13, 13, 16, 16, 16, 16, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 14, 14, 23, 24, 24, 24, 19, 19, 25, 20, 20, 20, 21, 28, 45, 48, 53, 55, 72, 85, 109, 123, 135, 142, 155, 243, 263, 297, 338, 392, 408, 446, 596, 652, 720, 736, 775, 814, 1008, 1074, 1142, 1226, 1275, 1322, 1369, 1399, 1452, 1484, 1526, 1539, 1583, 1614, 1636, 1667, 1690, 1691, 1698],
  waterUrl: "https://tiles.arcgis.com/tiles/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Plaine_Inondable_",
  buildingUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments/FeatureServer/",
  buildingPtsUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Batiments_pts/FeatureServer/",
  buildingFreestyleUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/Assomption_Freestyle/FeatureServer/",
  streetUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_Routes/FeatureServer/",
  bagUrl: "https://services6.arcgis.com/R0Zr4iUN1fGpuLFt/arcgis/rest/services/AssomptionFinal_SacsSable/FeatureServer/",
  incertMin_Url: "",
  incertMax_Url: "",
  photoUrls: [""],
  centerView: [-73.444359, 46.041463],
  centerViewZoom: 15,
  btn2019: 35, //340 m3s
  btnHier: 11,
  btnTempsReel: 16,
  btnUnJour: 23,
  btnTroisJours: 38,
  yearMarkers: [
    {
      value:24, //180
      label: '2',
    },
    {
      value: 54, //330
      label: '20',
    },
    {
      value: 67, //395
      label: '100',
    }
  ],
  // isVisible: false
};

