// import {vulnBuildingStatus} from "./popup";

// export const buildingRenderer = {
//   type: "unique-value",  // autocasts as new UniqueValueRenderer()
//   field: "ETAT",
//   uniqueValueInfos: [
//     //     {
//     //     value: "Normal",
//     //     label: "Non inondé",
//     //     symbol: {
//     //         type: "simple-fill",  // autocasts as new SimpleFillSymbol()
//     //         color: "green",
//     //         outline: {width: 0}
//     //     }
//     // },
//     {
//       value: "Isolé (route inondée)",
//       symbol: {
//         type: "simple-fill",  // autocasts as new SimpleFillSymbol()
//         color: "yellow",
//         outline: {width: 0}
//       },
//     }, {
//       value: "Sous-sol inondé" || "Eau atteint bâtiment",
//       label: "Sous-sol inondé",
//       symbol: {
//         type: "simple-fill",  // autocasts as new SimpleFillSymbol()
//         color: "red",
//         outline: {width: 0}
//       },
//     }, {
//       value: "Sous-sol et premier plancher inondé" || "Premier plancher inondé (sans sous-sol)",
//       label: "Sous-sol et premier plancher inondé",
//       symbol: {
//         type: "simple-fill",  // autocasts as new SimpleFillSymbol()
//         color: "red",
//         outline: {width: 0}
//       },
//     }],
// };
//
// export const buildingPtsRenderer = {
//   type: "unique-value",  // autocasts as new UniqueValueRenderer()
//   field: "clusterETA",
//   uniqueValueInfos: [
//     {
//       value: "isolé (route inondée)",
//       symbol: {
//         type: "simple-marker",  // autocasts as new SimpleFillSymbol()
//         color: "yellow",
//         // outline: {width: 0}
//       },
//     }, {
//       value: "inondé",
//       // label: "Inondé",
//       symbol: {
//         type: "simple-marker",  // autocasts as new SimpleFillSymbol()
//         color: "red",
//         // outline: {width: 0}
//       },
//     }],
// };

export const buildingFreestyleRenderer = {
  type: "simple",
  symbol: {
    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
    color: "brown",
    outline: {width: 0}
  },
};

// export const streetRenderer = {
//   type: "unique-value",  // autocasts as new UniqueValueRenderer()
//   field: "STATUS",
//   uniqueValueInfos: [
//     {
//       value: "Inondé - Praticable",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "yellow",
//         width: 3,
//         style: "solid"
//       }
//     }, {
//       value: "Inondé - Véhicules d'urgence seulement",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "orange",
//         width: 3,
//         style: "solid"
//       }
//     }, {
//       value: "Inondé - Impraticable",
//       symbol: {
//         type: "simple-line", // autocasts as new SimpleLineSymbol()
//         color: "red",
//         width: 3,
//         style: "solid"
//       }
//     }]
// };
export const streetRenderer = {
  type: "unique-value",
  field: "HzVehDiss",
  defaultSymbol: {type: "simple-fill"},
  uniqueValueInfos: [{
    value: "Low",
    symbol: {
      type: "simple-fill",
      color: [45, 130, 0, 0.7] //green
    }
  }, {
    value: "Medium",
    symbol: {
      type: "simple-fill",
      color: [250, 255, 0, 0.7] //yellow
    }
  }, {
    value: "High",
    symbol: {
      type: "simple-fill",
      color: [235, 0, 13, 0.7] //red
    }
  }]
};

// Pour sacs de sable
// export const bagRenderer = {
//   type: "simple",  // autocasts as new UniqueValueRenderer()
//   symbol: {
//     type: "simple-fill",  // autocasts as new SimpleLineSymbol()
//     color: [232, 32, 32, 0.5],
//     outline: {
//       // autocasts as new SimpleLineSymbol()
//       width: 2,
//       color: [232, 32, 32],
//     },
//   }
// };

export const bagRenderer = {
  type: "unique-value",
  field: "HzPedDiss",
  defaultSymbol: {type: "simple-fill"},
  uniqueValueInfos: [{
    value: "Low",
    symbol: {
      type: "simple-fill",
      color: [45, 130, 0, 0.7] //green
    }
  }, {
    value: "Medium",
    symbol: {
      type: "simple-fill",
      color: [250, 255, 0, 0.7] //yellow
    }
  }, {
    value: "High",
    symbol: {
      type: "simple-fill",
      color: [235, 0, 13, 0.7] //red
    }
  }]
};


export const dmgBuildingRenderer = {
  type: "class-breaks",
  field: "dmg",
  classBreakInfos: [
    {
      minValue: 0.000001,
      maxValue: 15000,
      symbol: {
        type: "simple-fill",
        color: [245, 207, 81, 0.7]
      }
    },
    {
      minValue: 15000.000001,
      maxValue: 50000,
      symbol: {
        type: "simple-fill",
        color: [202, 90, 99, 0.7]
      }
    }, {
      minValue: 50000.000001,
      maxValue: 100000,
      symbol: {
        type: "simple-fill",
        color: [238, 95, 92, 0.7]
      }
    },
    {
      minValue: 100000.000001,
      maxValue: 500000,
      symbol: {
        type: "simple-fill",
        color: [208, 77, 255, 0.7]
      }
    },
    {
      minValue: 500000.000001,
      maxValue: 999999999,
      symbol: {
        type: "simple-fill",
        color: [118, 157, 245, 0.7]
      }
    }
  ]

};
