import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import "./index.css";
// import App from "./containers/App"; //pour prod
import App from "./containers/App1"; //pour dev Assomption
import * as serviceWorker from "./utils/serviceWorker";
import Auth from "./utils/Auth";
import reducer from './store/reducer'

const auth = new Auth();

// const store = createStore(
//     reducer,
//     compose(
//         applyMiddleware(thunk),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// );
const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

let state = {};
window.setState = (changes) => {
    state = Object.assign({}, state, changes);
    ReactDOM.render(
        <Provider store={store}>
            {/*{console.log("state", state)}*/}
            <App {...state} store={store}/>
        </Provider>,
        document.getElementById("root")
    );
};

/* eslint no-restricted-globals: 0*/

let username = auth.getProfile().sub || "Geosapiens";

let initialState = {
    name: username,
    location: location.pathname.replace(/^\/?|\/$/g, ""),
    auth,
};

window.setState(initialState);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
