export const CHANGE_SCENARIO = 'CHANGE_SCENARIO';
export const TOGGLE_WATER = 'TOGGLE_WATER';
export const TOGGLE_WATER_PRESENT = 'TOGGLE_WATER_PRESENT';
export const TOGGLE_WATER_FUTUR = 'TOGGLE_WATER_FUTUR';
export const TOGGLE_BUILDING = 'TOGGLE_BUILDING';
export const TOGGLE_STREET = 'TOGGLE_STREET';
export const TOGGLE_BAG = 'TOGGLE_BAG';
export const TOGGLE_MIN = 'TOGGLE_MIN';
export const TOGGLE_MAX = 'TOGGLE_MAX';
export const TOGGLE_PHOTO = 'TOGGLE_PHOTO';
export const TOGGLE_ZI_2 = 'TOGGLE_ZI_2';
export const TOGGLE_ZI_20 = 'TOGGLE_ZI_20';
export const TOGGLE_ZI_100 = 'TOGGLE_ZI_100';
export const TOGGLE_ZI_LAVAL = 'TOGGLE_ZI_LAVAL';
export const TOGGLE_STATS = 'TOGGLE_STATS';
export const TOGGLE_CRISE = 'TOGGLE_CRISE';
export const TOGGLE_MANUAL_MODE = 'TOGGLE_MANUAL_MODE';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const TOGGLE_ALERTE = 'TOGGLE_ALERTE';
export const TOGGLE_VALIDATION = 'TOGGLE_VALIDATION';
export const TOGGLE_GRAPH = 'TOGGLE_GRAPH';
export const CHANGE_TYPE_MESURE = 'CHANGE_TYPE_MESURE';
export const CHANGE_MATCH_MEDIA = 'CHANGE_MATCH_MEDIA';
export const CHANGE_DATA_EXPORT = 'CHANGE_DATA_EXPORT';


export const changeScenario = (id) => {
  return {
    type: CHANGE_SCENARIO,
    scenarioId: id
  };
};

export const toggleWater = () => {
  return {
    type: TOGGLE_WATER,
  };
};

export const toggleWaterPresent = () => {
  return {
    type: TOGGLE_WATER_PRESENT,
  };
};

export const toggleWaterFutur = () => {
  return {
    type: TOGGLE_WATER_FUTUR,
  };
};

export const toggleBuilding = () => {
  return {
    type: TOGGLE_BUILDING,
  };
};

export const toggleStreet = () => {
  return {
    type: TOGGLE_STREET,
  };
};

export const toggleBag = () => {
  return {
    type: TOGGLE_BAG,
  };
};

export const toggleMin = () => {
  return {
    type: TOGGLE_MIN,
  };
};

export const toggleMax = () => {
  return {
    type: TOGGLE_MAX,
  };
};

export const togglePhoto = () => {
  return {
    type: TOGGLE_PHOTO,
  };
};

export const toggleZi2 = () => {
  return {
    type: TOGGLE_ZI_2,
  };
};

export const toggleZi20 = () => {
  return {
    type: TOGGLE_ZI_20,
  };
};

export const toggleZi100 = () => {
  return {
    type: TOGGLE_ZI_100,
  };
};

export const toggleZiLaval = () => {
  return {
    type: TOGGLE_ZI_LAVAL,
  };
};

export const toggleStats = () => {
  return {
    type: TOGGLE_STATS,
  };
};

export const toggleCrise = () => {
  return {
    type: TOGGLE_CRISE,
  };
};

// export const toggleManualMode = () => {
//     return {
//         type: TOGGLE_MANUAL_MODE,
//     };
// };

export const toggleSearch = () => {
  return {
    type: TOGGLE_SEARCH,
  };
};

export const toggleAlerte = () => {
  return {
    type: TOGGLE_ALERTE,
  };
};

export const toggleValidation = () => {
  return {
    type: TOGGLE_VALIDATION,
  };
};

export const toggleGraph = (activeGraph) => {
  return {
    type: TOGGLE_GRAPH,
    newActiveGraph: activeGraph
  };
};


export const changeTypeMesure = () => {
  return {
    type: CHANGE_TYPE_MESURE
  };
};

export const matchMediaChange = (mq) => {
  return {
    type: CHANGE_MATCH_MEDIA,
    widthMatches: mq.matches
  };
};

export const changeDataExport = (data) => {
  return {
    type: CHANGE_DATA_EXPORT,
    newData: data
  };
};


// EXEMPLE ASYNC
// export const saveStatsEvac = (count) => {
//     return {
//         type: CHANGE_STATS_EVAC,
//         statsCount: count
//     };
// };
//
// export const storeStatsEvac = (count) => {
//     return dispatch => {
//         setTimeout(() => {
//             dispatch(saveStatsEvac(count));
//         }, 2000);
//     }
// };
