import * as actionCreators from './actions';
import {updateObject} from './utility';


const initialState = {
  scenario: 0,
  water: true,
  waterPresent: true,
  waterFutur: false,
  building: false,
  street: false,
  bag: false,
  min: false,
  max: false,
  photo: false,
  zi2: false,
  zi20: false,
  zi100: false,
  ziLaval: false,
  // stats: true,
  stats: false,
  crise: false,
  // manualMode: true,
  // manualMode: false,
  // search: true,
  search: false,
  alerte: false,
  validation: false,
  graph: {
    statsIsole: false,
    statsSsInonde: false,
    statsPremInonde: false,
    statsTotalInonde: false,
    statsNbEvac: false
  },
  debit: true,
  criseOptionsLabel: {
    rec: "Recurrence",
    annees: "in years",
    btn2019: "2019",
    btnHier: "Situation yesterday",
    btnTempsReel: "Real time",
    btnUnJour: "1 day forecast",
    btnTroisJours: "3 day forecast",
  },
  dataExport: []
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.CHANGE_SCENARIO:
      return updateObject(state, {scenario: action.scenarioId});
    case actionCreators.TOGGLE_WATER:
      return updateObject(state, {water: !state.water});
    case actionCreators.TOGGLE_WATER_PRESENT:
      // return updateObject(state, {waterPresent: !state.waterPresent, waterFutur: !state.waterFutur});
      return updateObject(state, {waterPresent: true, waterFutur: false});
    case actionCreators.TOGGLE_WATER_FUTUR:
      // return updateObject(state, {waterFutur: !state.waterFutur, waterPresent: !state.waterPresent});
      return updateObject(state, {waterFutur: true, waterPresent: false});
    case actionCreators.TOGGLE_BUILDING:
      return updateObject(state, {building: !state.building});
    case actionCreators.TOGGLE_STREET:
      return updateObject(state, {street: !state.street});
    case actionCreators.TOGGLE_BAG:
      return updateObject(state, {bag: !state.bag});
    case actionCreators.TOGGLE_MIN:
      return updateObject(state, {min: !state.min});
    case actionCreators.TOGGLE_MAX:
      return updateObject(state, {max: !state.max});
    case actionCreators.TOGGLE_PHOTO:
      return updateObject(state, {photo: !state.photo});
    case actionCreators.TOGGLE_ZI_2:
      return updateObject(state, {zi2: !state.zi2, zi20: false, zi100: false, ziLaval: false});
    case actionCreators.TOGGLE_ZI_20:
      return updateObject(state, {zi20: !state.zi20, zi2: false, zi100: false, ziLaval: false});
    case actionCreators.TOGGLE_ZI_100:
      return updateObject(state, {zi100: !state.zi100, zi2: false, zi20: false, ziLaval: false});
    case actionCreators.TOGGLE_ZI_LAVAL:
      return updateObject(state, {ziLaval: !state.ziLaval, zi2: false, zi20: false, zi100: false});
    case actionCreators.TOGGLE_STATS:
      return updateObject(state, {stats: !state.stats});
    case actionCreators.TOGGLE_CRISE:
      return updateObject(state, {crise: !state.crise});
    // case actionCreators.TOGGLE_MANUAL_MODE:
    //   return updateObject(state, {manualMode: !state.manualMode, stats: state.manualMode});
    case actionCreators.TOGGLE_SEARCH:
      return updateObject(state, {search: !state.search});
    case actionCreators.TOGGLE_ALERTE:
      return updateObject(state, {alerte: !state.alerte});
    case actionCreators.TOGGLE_VALIDATION:
      return updateObject(state, {validation: !state.validation});

    case actionCreators.TOGGLE_GRAPH:
      let newState = {
        statsIsole: false,
        statsSsInonde: false,
        statsPremInonde: false,
        statsTotalInonde: false,
        statsNbEvac: false
      };
      if (action.newActiveGraph === "statsIsole") {
        newState = {
          ...newState,
          statsIsole: !state.graph.statsIsole
        }
      }
      if (action.newActiveGraph === "statsSsInonde") {
        newState = {
          ...newState,
          statsSsInonde: !state.graph.statsSsInonde
        }
      }
      if (action.newActiveGraph === "statsPremInonde") {
        newState = {
          ...newState,
          statsPremInonde: !state.graph.statsPremInonde
        }
      }
      if (action.newActiveGraph === "statsTotalInonde") {
        newState = {
          ...newState,
          statsTotalInonde: !state.graph.statsTotalInonde
        }
      }
      if (action.newActiveGraph === "statsNbEvac") {
        newState = {
          ...newState,
          statsNbEvac: !state.graph.statsNbEvac
        }
      }
      return updateObject(state, {graph: newState});

    case actionCreators.CHANGE_TYPE_MESURE:
      return updateObject(state, {debit: !state.debit});
    case actionCreators.CHANGE_MATCH_MEDIA:
      return updateObject(state, changeWidth(action.widthMatches));
    case actionCreators.CHANGE_DATA_EXPORT:
      return updateObject(state, {dataExport: action.newData});
    default:
      return state;
  }
};

const changeWidth = (widthMatches) => {
  if (widthMatches) {
    return {
      criseOptionsLabel: {
        rec: "",
        annees: "",
        btn2019: "2019",
        btnHier: "Yesterday",
        btnTempsReel: "Real time",
        btnUnJour: "1 day",
        btnTroisJours: "3 day"
      },
    };
  }
  return {
    criseOptionsLabel: {
      rec: "Recurrence",
      annees: "in years",
      btn2019: "2019",
      btnHier: "Situation yesterday",
      btnTempsReel: "Real time",
      btnUnJour: "1 day forecast",
      btnTroisJours: "3 day forecast",
    },
  };
};

export default reducer;