import React from "react";
import "./Zi.css";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";

const Zi = (props) => {
  return (
    <>
      <button className={props.toggleZi2 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-2" onClick={props.onZi2Toggle}>
        Census districts
      </button>
      <button className={props.toggleZi20 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-20" onClick={props.onZi20Toggle}>
        Zip codes
      </button>
      {/*<button className={props.toggleZi100 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-100" onClick={props.onZi100Toggle}>*/}
      {/*  100*/}
      {/*</button>*/}
      {/*<button className={props.toggleZiLaval ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-Laval" onClick={props.onZiLavalToggle}>*/}
      {/*  L*/}
      {/*</button>*/}
    </>
  );
};

const mapStateToProps = (state) => {
    return {
        toggleZi2: state.zi2,
        toggleZi20: state.zi20,
        // toggleZi100: state.zi100,
        // toggleZiLaval: state.ziLaval,
    };
};

const mapDispatchToProps = dispatch => {
  return {
    onZi2Toggle: () => dispatch(actionCreators.toggleZi2()),
    onZi20Toggle: () => dispatch(actionCreators.toggleZi20()),
    // onZi100Toggle: () => dispatch(actionCreators.toggleZi100()),
    // onZiLavalToggle: () => dispatch(actionCreators.toggleZiLaval()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Zi);