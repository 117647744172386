import React from "react";
import Slider from "@material-ui/core/Slider";
import Logo from "../assets/logo-e-nundation_transparent.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPowerOff,
    faWater,
    faArrowUp,
    faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as ModeCrise} from "../assets/icons/ico_modecrise.svg";
import {ReactComponent as Repeat} from "../assets/icons/ico_repeat.svg";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";


const ScaleEntry = ({scaleMarksList}) => (
    <>
        {scaleMarksList.map((scaleMark) => (
            <div
                className="header_slider_input_scale"
                style={scaleMark.style}
                key={scaleMark.value}
            >
                {scaleMark.label}
            </div>
        ))}
    </>
);

const scaleMarksList = [];

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.scenarioDepart,
            // value: this.props.currentScenario,
        };
        this.updateSliderValue = this.updateSliderValue.bind(this);
        // this.generateScaleMarksList();
    }

    // generateScaleMarksList() {
    //     let uniteMesure = this.props.typeMesureDebit ? " m³/s" : " m";
    //     const scale = 20;
    //     const max = this.props.nbScenario - 1;
    //     const min = 0;
    //     const padding = 0;
    //
    //     for (let index = min; index < max; index += scale) {
    //         const position = Math.round((index / (max - min)) * 100);
    //
    //         if (position < 100) {
    //             scaleMarksList.push({
    //                 value: index,
    //                 style: {left: `calc(${position}% + ${padding}px)`},
    //                 // label: `${this.props.labels[index]} ${this.props.uniteMesure}`,
    //                 label: `${this.props.labels[index]} ${uniteMesure}`,
    //                 // label: this.props.labels[index] + uniteMesure,
    //             });
    //         }
    //     }
    // }

    updateSliderValue(value) {
        this.setState({value: parseInt(value.target.value, 10)});
        const waterLevelEvent = new CustomEvent("waterLevel", {
            detail: parseInt(value.target.value, 10),
        });
        document.dispatchEvent(waterLevelEvent);
    }

    EmitSliderValue(value) {
        this.setState({value: value});
        const waterLevelEvent = new CustomEvent("waterLevel", {detail: value});
        document.dispatchEvent(waterLevelEvent);
    }

    render() {
        // Change type of mesure
        // let typeMesure = this.props.typeMesureDebit ? "River flow" : "Water level";
        let uniteMesure = this.props.typeMesureDebit ? " years" : " m";

        return (
            <header className="header">
                <img className="header_logo" src={Logo} alt="logo"/>
                <div className="slider_container">
                    <div className="slider_header_container">
                        <div className="slider_header_wrapper">
                            <div className="header_slider">
                                {/*<span className="header_slider_text">*/}
                                {/*    <FontAwesomeIcon icon={faWater}/>{" "}*/}
                                {/*    <span className="typeMesureContainer">*/}
                                {/*        {typeMesure}*/}
                                {/*    </span>*/}
                                {/* </span>*/}
                                <span className="header_slider_text_below">
                                    <Repeat/>{" "}
                                    <span className="typeMesureContainer">
                                        {this.props.criseOptionsLabel.rec}
                                        <br/>
                                        {this.props.criseOptionsLabel.annees}
                                    </span>
                                </span>
                            </div>
                            <div className="slider_header_container_wrap">
                                <ScaleEntry scaleMarksList={scaleMarksList}/>

                                <Slider
                                    id="slider"
                                    className="header_slider_input"
                                    min={0}
                                    max={this.props.nbScenario - 1}
                                    step={1}
                                    defaultValue={this.props.scenarioDepart}
                                    value={this.state.value}
                                    valueLabelFormat={`${this.props.labels[this.state.value]} ${uniteMesure}`}
                                    valueLabelDisplay="on"
                                    marks={this.props.yearMarkers}
                                    onChange={(_, value) => this.EmitSliderValue(value)}
                                />
                            </div>
                        </div>
                        <div className="gestion_crise">
                            <div>
                            <button
                                id="btnCrise"
                                className={this.props.toggleCrise ? "btnCriseTrue" : "btnCriseFalse"}
                                onClick={this.props.onCriseToggle}
                            >
                                <ModeCrise className={this.props.toggleCrise ? "btnModeCriseTrue" : "btnModeCriseFalse"}/>
                                <span>
                                    {this.props.toggleCrise ? "Close crisis management mode" : "Open crisis management mode"}
                                </span>
                                <FontAwesomeIcon id="arrowUp" icon={faArrowUp} style={{display: this.props.toggleCrise ? 'block' : 'none' }}/>
                                <FontAwesomeIcon id="arrowDown" icon={faArrowDown} style={{display: this.props.toggleCrise ? 'none' : 'block' }}/>
                            </button>
                            </div>
                            {/*<button*/}
                            {/*    id="btnManualMode"*/}
                            {/*    className={this.props.toggleWaterFutur ? "btnCriseTrue" : "btnCriseFalse"}*/}
                            {/*    onClick={this.props.onWaterFuturToggle}*/}
                            {/*>*/}
                            {/*    <span>*/}
                            {/*        {this.props.toggleWaterFutur ? "Present" : "Futur"}*/}
                            {/*    </span>*/}
                            {/*</button>*/}
                            <div>
                            <input type="radio" id="btn_present" name="water_layer" value="Present" onClick={this.props.onWaterPresentToggle} defaultChecked/>
                            <label htmlFor="present">Present</label>
                            <input type="radio" id="btn_futur" name="water_layer" value="Futur" onClick={this.props.onWaterFuturToggle}/>
                            <label htmlFor="futur">Futur</label>
                            </div>
                            <div id="div-manual"  style={{display: this.props.toggleManualMode ? 'block' : 'none'}}/>
                        </div>
                        <div className="header_actions" style={{display: this.props.toggleCrise ? 'block' : 'none' }}>
                            <button
                                id="btn_2019"
                                onClick={this.updateSliderValue}
                                value={this.props.btn2019}
                            >
                                {this.props.criseOptionsLabel.btn2019}
                            </button>
                            <button
                                id="btn_hier"
                                onClick={this.updateSliderValue}
                                value={this.props.btnHier}
                            >
                                {this.props.criseOptionsLabel.btnHier}
                            </button>
                            <button
                                id="btn_temps_reel"
                                onClick={this.updateSliderValue}
                                value={this.props.btnTempsReel}
                            >
                                {this.props.criseOptionsLabel.btnTempsReel}
                            </button>
                            <button
                                id="btn_1_jour"
                                onClick={this.updateSliderValue}
                                value={this.props.btnUnJour}
                            >
                                {this.props.criseOptionsLabel.btnUnJour}
                            </button>
                            <button
                                id="btn_3_jours"
                                onClick={this.updateSliderValue}
                                value={this.props.btnTroisJours}
                            >
                                {this.props.criseOptionsLabel.btnTroisJours}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="btn_signout" onClick={this.props.signOut}>
                        {" "}
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </button>
                </div>
            </header>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        typeMesureDebit: state.debit,
        criseOptionsLabel: state.criseOptionsLabel,
        toggleCrise: state.crise,
        toggleWaterPresent: state.waterPresent,
        toggleWaterFutur: state.waterFutur
        // toggleManualMode: state.manualMode
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCriseToggle: () => dispatch(actionCreators.toggleCrise()),
        onWaterPresentToggle: () => dispatch(actionCreators.toggleWaterPresent()),
        onWaterFuturToggle: () => dispatch(actionCreators.toggleWaterFutur())
        // onManualModeToggle: () => dispatch(actionCreators.toggleManualMode())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
// export default Header;
