import React from "react";
import {connect} from "react-redux";
import Header from "../components/Header";
import BottomPanel from "../components/BottomPanel";
import {WaterLevelMap} from "../components/WaterLevelMap";
// import {AS} from "../constants/appsConstants";
import {SUEZ} from "../constants/appsConstants";
// import {Mentions} from "../components/Mentions";
import {Legende} from "../components/Legende";
import Validation from "../components/Validation";

export class App1 extends React.Component {
  // state = { exportDataSet: [] };
  // callbackFunction = (childData) => this.setState({ exportDataSet: childData });

  render() {
    return (
      <div className="app">
        <Header
          scenarioDepart={SUEZ.scenarioDepart}
          nbScenario={SUEZ.nbScenario}
          labels={SUEZ.labels}
          btn2019={SUEZ.btn2019}
          btnHier={SUEZ.btnHier}
          btnTempsReel={SUEZ.btnTempsReel}
          btnUnJour={SUEZ.btnUnJour}
          btnTroisJours={SUEZ.btnTroisJours}
          yearMarkers={SUEZ.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={SUEZ.scenarioDepart}
          labels={SUEZ.labels}
          dataIsole={SUEZ.dataIsole}
          dataSS={SUEZ.dataSS}
          dataPrem={SUEZ.dataPrem}
          dataTot={SUEZ.dataTot}
          dataEvac={SUEZ.dataEvac}
          // exportDataSet={this.state.exportDataSet}
        />

        <WaterLevelMap
          configuration={SUEZ}
          // exportDataSetCallback={this.callbackFunction}
          labels={SUEZ.labels}
          store={this.props.store}
        />

        {/*<Mentions/>*/}
        <Legende/>
        <Validation/>
      </div>
    );
  }
}
export default connect(null, null)(App1);

// export default class App1 extends React.Component {
//   // state = { exportDataSet: [] };
//   // callbackFunction = (childData) => this.setState({ exportDataSet: childData });
//
//   render() {
//     return (
//       <div className="app">
//         <Header
//           scenarioDepart={AS.scenarioDepart}
//           nbScenario={AS.nbScenario}
//           labels={AS.labels}
//           btn2019={AS.btn2019}
//           btnHier={AS.btnHier}
//           btnTempsReel={AS.btnTempsReel}
//           btnUnJour={AS.btnUnJour}
//           btnTroisJours={AS.btnTroisJours}
//           yearMarkers={AS.yearMarkers}
//           signOut={this.props.auth.signOut}
//         />
//
//         <BottomPanel
//           scenarioDepart={AS.scenarioDepart}
//           labels={AS.labels}
//           dataIsole={AS.dataIsole}
//           dataSS={AS.dataSS}
//           dataPrem={AS.dataPrem}
//           dataTot={AS.dataTot}
//           dataEvac={AS.dataEvac}
//           // exportDataSet={this.state.exportDataSet}
//         />
//
//         <WaterLevelMap
//           configuration={AS}
//           // exportDataSetCallback={this.callbackFunction}
//           labels={AS.labels}
//           store={this.props.store}
//         />
//
//         <Mentions/>
//         <Legende/>
//         {/*<Measure/>*/}
//
//       </div>
//     );
//   }
// }
